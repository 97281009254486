import { Chat } from 'api/chat'
import { IMG, MimeType } from 'api/user-file'
import { FC, ComponentProps, useState, DragEventHandler, useCallback } from 'react'
import { cn } from 'utils'
import styles from './dropzone.module.scss'
import { useChatCoreContext } from '../core/core-context'

interface Props extends ComponentProps<'div'> {
  disabled?: boolean
}

export const ChatDropzone: FC<Props> = ({ children, className, disabled, ...props }) => {
  const { chat, handleAction } = useChatCoreContext()
  const [dragging, setDradding] = useState(false)

  const sendFile = useCallback(
    (file: File) => {
      handleAction({
        type: Chat.MessageActionType.Send,
        data: { file, chat_id: chat.chat_id, message: '' },
      })
    },
    [chat.chat_id, handleAction],
  )

  const handleDragEnter = useCallback<DragEventHandler>((event) => {
    if (event.dataTransfer?.items?.length !== 1) return
    const item = event.dataTransfer.items[0]
    if (!IMG.includes(item.type as MimeType)) return
    event.preventDefault()
    setDradding(true)
  }, [])

  const handleDragExit = useCallback<DragEventHandler>(() => setDradding(false), [])

  const handleDrop = useCallback<DragEventHandler>(
    (event) => {
      event.preventDefault()
      setDradding(false)
      if (event.dataTransfer?.items?.length !== 1) return
      if (!IMG.includes(event.dataTransfer.items[0].type as MimeType)) return
      sendFile(event.dataTransfer.files[0])
    },
    [sendFile],
  )

  return (
    <div
      {...props}
      className={cn(styles.dropzone, className)}
      {...(disabled
        ? {}
        : {
            onDragEnter: handleDragEnter,
            onDragOver: handleDragEnter,
            onDragLeave: handleDragExit,
            onDrop: handleDrop,
            ...(dragging && { 'data-drag-over': true }),
          })}
    >
      {children}
    </div>
  )
}
