import { Chat } from 'api/chat'
import { IconClose, IconSend } from 'icons'
import { FC, ComponentProps, useCallback, KeyboardEventHandler, useRef } from 'react'
import {
  Button,
  Description,
  FilesPreview,
  Form,
  FormValue,
  HiddenField,
  IconButton,
  SubmitButton,
  TextAreaField,
  Tooltip,
} from 'ui'
import { cn } from 'utils'
import { ChatAttachButton } from './chat-attach-button'
import { ChatComposerAttachment } from './chat-composer-attachment'
import styles from './chat-composer.module.scss'
import { useChatCoreContext } from '../core/core-context'
import { useChatSetTyping } from '../use-chat-set-typing'
import { ChatMessageTime } from '../util/chat-message-time'

interface Props
  extends Omit<ComponentProps<typeof Form<Data>>, 'children' | 'onSubmit' | 'defaultValues'> {
  showReplyName?: boolean
  onBeforeFirstMessage?: () => Promise<void>
}

type Data = Chat.MessageUpdate & {
  _is_first_message?: 'true'
}

export const ChatComposer: FC<Props> = ({
  className,
  showReplyName,
  onBeforeFirstMessage,
  ...props
}) => {
  const { chat, contextAction, handleAction, userNameMap, messages } = useChatCoreContext()

  const selectNone = useCallback(
    () => handleAction({ type: Chat.MessageActionType.ClearContext }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const updateTyping = useChatSetTyping(chat)

  const formRef = useRef<Form<Data>>(null)
  const handleKeyDown = useCallback<KeyboardEventHandler<HTMLTextAreaElement>>(
    (event) => {
      switch (event.key) {
        case 'Escape':
          if (contextAction) {
            event.preventDefault()
            event.stopPropagation()
            selectNone()
          }
          break

        case 'Enter':
          if (event.metaKey || event.ctrlKey || event.shiftKey) {
            event.preventDefault()
            event.currentTarget.form?.requestSubmit()
          }
          break

        default:
          updateTyping()
          break
      }
    },
    [contextAction, selectNone, updateTyping],
  )

  const message = contextAction?.message
  const submit = useCallback(
    async ({ message_id, _is_first_message, ...data }: Data) => {
      if (_is_first_message) await onBeforeFirstMessage?.()
      await Promise.resolve(
        message && message_id
          ? handleAction({
              type: Chat.MessageActionType.Update,
              data: { message_id, ...data },
              message,
            })
          : handleAction({ type: Chat.MessageActionType.Send, data }),
      ).then(() => {
        //@ts-ignore
        formRef.current?.reset?.()
        selectNone()
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [message],
  )

  const isEdit = Chat.isMessageActionEdit(contextAction)
  const isReply = Chat.isMessageActionReply(contextAction)

  return (
    <Form {...props} className={cn(styles.form, className)} onSubmit={submit} ref={formRef}>
      <HiddenField name="chat_id" defaultValue={chat.chat_id} />

      {onBeforeFirstMessage && !messages.some((msg) => !!msg.user_id) && (
        <HiddenField name="_is_first_message" defaultValue="true" />
      )}

      {message && isEdit && (
        <Description className={styles.context}>
          Edit message from <ChatMessageTime value={message.created_at} />.{' '}
          <Button theme="link" onClick={selectNone}>
            Cancel
          </Button>
          <HiddenField name="message_id" defaultValue={message.message_id} />
        </Description>
      )}

      <FilesPreview
        name="file"
        className={styles.filePreview}
        renderFile={(props) => <ChatComposerAttachment {...props} className={styles.file} />}
      />

      {message && isReply && (
        <Description className={cn(styles.context, styles.reply)}>
          <div className={styles.title}>
            <span>Reply to</span>{' '}
            {showReplyName && (
              <span className={styles.targetAuthor}>
                {message.user_id && userNameMap[message.user_id]}
              </span>
            )}
          </div>
          <div className={styles.targetMessage}>{message.message}</div>
          <IconButton onClick={selectNone} className={styles.cancel}>
            <IconClose />
          </IconButton>
          <HiddenField name="reply_to_id" defaultValue={message.message_id} />
        </Description>
      )}

      <TextAreaField
        name="message"
        className={styles.message}
        onKeyDown={handleKeyDown}
        defaultValue={isEdit ? contextAction?.message.message : undefined}
      />

      <FormValue<Data>>
        {({ values }) => <span className={styles.ghost}>{values.message}</span>}
      </FormValue>

      <ChatAttachButton name="file" className={styles.attach} disabled={isEdit} />

      <FormValue<Data>>
        {({ values }) => (
          <SubmitButton
            className={styles.submit}
            data-tooltip-content={`${getLabel(contextAction?.type)} (Shift + Enter)`}
            data-tooltip-id={Tooltip.ID}
            disabled={!(values.message || values.file)}
          >
            <IconSend className={styles.icon} />
          </SubmitButton>
        )}
      </FormValue>
    </Form>
  )
}

const getLabel = (type?: Chat.MessageActionType) =>
  type === Chat.MessageActionType.Edit
    ? 'Update'
    : type === Chat.MessageActionType.Reply
    ? 'Reply'
    : 'Send'
